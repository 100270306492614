<template>
  <bg-modal
    v-model="isShowModal"
    class="confirmation-modal"
    data-testid="addTenantConfirmationModal"
    desktop-size="lg"
    title="Yakin menyimpan data penyewa ini?"
    button-main-text="Ya, Simpan"
    description="Periksa kembali data penyewa berikut sebelum disimpan ke sistem. Contract ID akan muncul setelah data tersimpan."
    @click-main-action="$emit('confirm')"
  >
    <bg-divider class="confirmation-modal__divider" />

    <bg-grid class="confirmation-modal__tenant-info">
      <bg-grid-item :col="3">
        <bg-text size="title-5">Nama</bg-text>
        <bg-text size="body-2">{{ tenantName }}</bg-text>
      </bg-grid-item>
      <bg-grid-item :col="3">
        <bg-text size="title-5">Nomor Handphone</bg-text>
        <bg-text size="body-2">{{ phoneNumber }}</bg-text>
      </bg-grid-item>
      <bg-grid-item :col="3">
        <bg-text size="title-5">Nomor Kamar</bg-text>
        <bg-text size="body-2">{{ roomName }}</bg-text>
      </bg-grid-item>
      <bg-grid-item :col="3">
        <bg-text size="title-5">Tanggal Jatuh Tempo</bg-text>
        <bg-text size="body-2">{{ formattedDueDate }}</bg-text>
      </bg-grid-item>
    </bg-grid>

    <bg-grid v-if="isDp">
      <bg-grid-item :col="6">
        <bg-card data-testid="dpFirstPaymentSummary_card">
          <bg-text size="heading-5" class="confirmation-modal__payment-title"
            >Informasi Pembayaran</bg-text
          >

          <bg-text class="confirmation-modal__payment-subtitle"
            >Pembayaran Pertama</bg-text
          >

          <price-list :prices="firstPaymentPrices" />

          <bg-divider dashed class="confirmation-modal__divider" />

          <price-list-item
            label="Total Pembayaran Pertama"
            :price="firstPaymentPricesTotal"
            label-size="heading-6"
            price-label-size="heading-6"
          />

          <price-list-item
            v-if="dp"
            v-bind="dp"
            is-highlight-label
            is-highlight-price-label
          />

          <price-list-item
            v-if="firstPaymentRemainingPayment"
            v-bind="firstPaymentRemainingPayment"
          />
        </bg-card>
      </bg-grid-item>
      <bg-grid-item :col="6">
        <bg-card data-testid="dpNextPaymentSummary_card">
          <bg-text class="confirmation-modal__payment-subtitle"
            >Pembayaran Selanjutnya</bg-text
          >

          <price-list :prices="nextPaymentPrices" />

          <bg-divider dashed class="confirmation-modal__divider" />

          <price-list-item
            label="Total Pembayaran Selanjutnya"
            :price="nextPaymentPricesTotal"
            label-size="heading-6"
            price-label-size="heading-6"
          />
        </bg-card>
      </bg-grid-item>
    </bg-grid>

    <bg-card v-else data-testid="fullPaymentSummary_card">
      <bg-text class="confirmation-modal__payment-title"
        >Informasi Pembayaran</bg-text
      >

      <price-list :prices="firstPaymentPrices" />

      <bg-divider dashed class="confirmation-modal__divider" />

      <price-list-item
        label="Total Pembayaran"
        :price="firstPaymentPricesTotal"
        label-size="heading-6"
        price-label-size="heading-6"
      />
    </bg-card>
  </bg-modal>
</template>

<script>
import {
  BgCard,
  BgGrid,
  BgGridItem,
  BgText,
  BgModal,
  BgDivider,
} from 'bangul-vue';

import { useModalVisibility } from '@/composables/useModalVisibility';
import { dateFormatterToDisplay } from '@/utils/formatter';

import PriceList from '../PriceList';
import PriceListItem from '../PriceListItem';

const pricePropOption = {
  type: Object,
  default: null,
};

export default {
  name: 'AddTenantConfirmationModal',
  components: {
    BgCard,
    BgGrid,
    BgGridItem,
    BgText,
    BgModal,
    BgDivider,
    PriceList,
    PriceListItem,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    paymentMethod: {
      type: String,
      default: 'full',
    },
    additionalPrices: {
      type: Array,
      required: true,
    },
    roomPrice: pricePropOption,
    deposit: pricePropOption,
    flashSale: pricePropOption,
    dp: pricePropOption,
    tenantName: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    roomName: {
      type: String,
      default: '',
    },
    dueDate: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { isShowModal } = useModalVisibility(props, emit);

    return {
      isShowModal,
    };
  },
  computed: {
    isDp() {
      return this.paymentMethod === 'dp';
    },
    additionalPricesData() {
      if (this.additionalPrices.length) {
        return {
          label: 'Biaya Tambahan',
          details: this.additionalPrices,
        };
      }

      return null;
    },
    flashSaleData() {
      if (this.flashSale?.price) {
        return { ...this.flashSale, isHighlightPriceLabel: true };
      }

      return null;
    },
    firstPaymentPrices() {
      const prices = [this.roomPrice];

      if (this.deposit?.price) {
        prices.push(this.deposit);
      }

      if (this.additionalPricesData) {
        prices.push(this.additionalPricesData);
      }

      if (this.flashSaleData) {
        prices.push(this.flashSaleData);
      }

      return prices;
    },
    nextPaymentPrices() {
      if (!this.isDp) return [];

      const prices = [this.roomPrice];

      if (this.additionalPricesData) {
        prices.push(this.additionalPricesData);
      }

      return prices;
    },
    firstPaymentPricesTotal() {
      return this.countPriceTotal(this.firstPaymentPrices);
    },
    nextPaymentPricesTotal() {
      return this.countPriceTotal(this.nextPaymentPrices);
    },
    firstPaymentRemainingPayment() {
      if (!this.isDp) return null;

      return {
        label: 'Sisa Pelunasan',
        price: this.firstPaymentPricesTotal - (this.dp?.price || 0),
      };
    },
    formattedDueDate() {
      return dateFormatterToDisplay(this.dueDate, 'D MMMM YYYY');
    },
  },
  methods: {
    countPriceTotal(prices) {
      return prices.reduce((totalPrice, currentPrice) => {
        if (currentPrice?.details) {
          const totalPriceDetails = this.countPriceTotal(currentPrice.details);
          return totalPrice + totalPriceDetails;
        }

        return totalPrice + (currentPrice?.price || 0);
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped src="./AddTenantConfirmationModal.scss"></style>
