<template>
  <bg-list-item class="price-list-item">
    <template #left-content>
      <bg-text
        :class="[
          'price-list-item__label',
          { 'price-list-item__label--highlight': isHighlightLabel },
        ]"
        :size="labelSize"
        >{{ label }}</bg-text
      >
    </template>
    <template #right-content v-if="price">
      <bg-text
        :class="[
          'price-list-item__price-label',
          { 'price-list-item__price-label--highlight': isHighlightPriceLabel },
        ]"
        :size="priceLabelSize"
        >{{ price | rupiahFormatter }}</bg-text
      >
    </template>
  </bg-list-item>
</template>

<script>
import { BgListItem, BgText } from 'bangul-vue';
import { rupiahFormatter } from 'Utils/formatter';

export default {
  name: 'PriceListItem',
  components: { BgListItem, BgText },
  props: {
    label: {
      type: String,
      default: '',
    },
    labelSize: {
      type: String,
      default: 'body-2',
    },
    price: {
      type: Number,
      default: 0,
    },
    priceLabelSize: {
      type: String,
      default: 'body-1',
    },
    isHighlightLabel: {
      type: Boolean,
      default: false,
    },
    isHighlightPriceLabel: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    rupiahFormatter,
  },
};
</script>

<style lang="scss" src="./PriceListItem.scss" scoped></style>
