<template>
  <ul class="price-list">
    <li class="price-list__item" v-for="(price, i) in prices" :key="i">
      <template v-if="price.details">
        <template v-if="price.details.length">
          <price-list-item :label="price.label" />
          <ul class="price-list__item-details">
            <li v-for="(priceDetail, j) in price.details" :key="j">
              <price-list-item v-bind="priceDetail" />
            </li>
          </ul>
        </template>
      </template>
      <price-list-item v-else v-bind="price" />
    </li>
  </ul>
</template>

<script>
import PriceListItem from '../PriceListItem';

export default {
  name: 'PriceList',
  components: {
    PriceListItem,
  },
  props: {
    prices: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" src="./PriceList.scss" scoped></style>
