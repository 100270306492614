<template>
  <add-tenant-wrapper
    ref="wrapper"
    type-label="New Booking"
    :listing-id.sync="listingId"
    @property-fetched="property = $event"
  >
    <template #form>
      <add-tenant-information-section
        ref="tenantInformation"
        is-autofill
        has-id-card-input
      />

      <bg-divider class="add-tenant__divider" />

      <add-tenant-payment-section
        ref="payment"
        :disabled="!isValidPricesData"
        :listing-id="listingPropertyId"
        :rent-price="totalRentPrice"
        :rent-type-options="rentTypeOptions"
        :price-data="roomPrices || {}"
        @room-changed="selectedRoom = $event"
        @rent-type-changed="rentType = $event"
      />

      <bg-divider class="add-tenant__divider" />

      <add-tenant-additional-fee
        v-if="listingPropertyId"
        :additional-fees="listingAdditionalFees"
        :loading="isFetchingAdditionalFees"
        :property-id="propertyId"
        :listing-id="listingPropertyId"
        @change="additionalPrices = $event"
      />

      <bg-button
        variant="primary"
        size="lg"
        class="mt-32"
        @click="handleSaveButtonClicked"
        >Simpan</bg-button
      >
    </template>

    <add-tenant-confirmation-modal
      v-if="bookingData"
      v-model="isShowConfirmationModal"
      :room-price="roomPrice"
      :dp="dpPrice"
      :flash-sale="flashSalePrice"
      :deposit="deposit"
      :additional-prices="bookingData.additional_price"
      :payment-method="bookingData.first_payment_type"
      :tenant-name="bookingData.contact_name"
      :phone-number="bookingData.contact_phone"
      :due-date="bookingData.checkin"
      :room-name="selectedRoomName"
      @confirm="submitNewBooking"
    />

    <loading-overlay
      :value="isSubmittingNewBooking || isFetchingListingPrices"
    />
  </add-tenant-wrapper>
</template>

<script>
import { mapMutations } from 'vuex';
import { BgDivider, BgButton } from 'bangul-vue';

import bookingAPI from '@admin/api/endpoints/booking';

import LoadingOverlay from '@molecules/LoadingOverlay';

import AddTenantAdditionalFee from '../../components/AddTenantAdditionalFee';
import AddTenantWrapper from '../../components/AddTenantWrapper';
import AddTenantInformationSection from '../../components/AddTenantInformationSection';
import AddTenantConfirmationModal from '../../components/AddTenantConfirmationModal';
import AddTenantPaymentSection from '../../components/AddTenantPaymentSection';

import mixinAddTenantDataProvider from '../../mixins/addTenantDataProvider';

export default {
  name: 'RoomAllotmentAddTenantNewBooking',

  components: {
    BgDivider,
    BgButton,
    AddTenantWrapper,
    LoadingOverlay,
    AddTenantAdditionalFee,
    AddTenantInformationSection,
    AddTenantConfirmationModal,
    AddTenantPaymentSection,
  },

  mixins: [mixinAddTenantDataProvider],

  data() {
    return {
      bookingData: null,
      isShowConfirmationModal: false,
      isSubmittingNewBooking: false,
    };
  },

  methods: {
    ...mapMutations('calendarView', ['setFromSubmitNewBookingState']),

    async handleSaveButtonClicked() {
      if (!this.$refs.wrapper?.validateForm) {
        return;
      }

      const isValid = await this.$refs.wrapper.validateForm();

      if (isValid) {
        this.bookingData = this.getAllBookingData();
        this.isShowConfirmationModal = true;
      }
    },
    getAllBookingData() {
      const { tenantInformation, payment } = this.$refs;

      const params = {
        booking_type: 'new',
        room_id: this.listingPropertyId,
        ...tenantInformation.getValues(),
        ...payment.getValues(),
        additional_price: this.additionalPrices,
      };

      return params;
    },
    async submitNewBooking() {
      this.isSubmittingNewBooking = true;
      this.isShowConfirmationModal = false;

      try {
        const bookingParams = this.getAllBookingData();
        bookingParams.additional_price = bookingParams.additional_price.map(
          ({ label, price }) => ({
            key: label,
            value: price,
          })
        );

        const res = await bookingAPI.postNewBooking(bookingParams);

        if (res?.status === 200) {
          this.setFromSubmitNewBookingState(true);
          this.$router.push({
            name: 'room-allotment-calendar-view',
            query: {
              property_id: this.$route.params.propertyId,
            },
          });
        } else {
          const error = res?.data?.error || 'Terjadi galat, silakan coba lagi.';
          this.$toast.show(error);
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || 'Terjadi galat, silakan coba lagi.';
        this.$toast.show(errorMessage);
        this.$error.report(error);
      } finally {
        this.isSubmittingNewBooking = false;
      }
    },
  },
};
</script>
